import ButtonBase from '@mui/material/ButtonBase'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip } from '@mui/material'
import { NorthOutlined, SouthOutlined } from '@mui/icons-material'
import { React, useMemo, useRef, useEffect, useState } from 'react'
import { formatDate, formatTime } from '../utils/dateUtils.js'
import { calculateColorBasedOnPercent } from '../utils/colorOptions.js'
import { WtxColors } from '@wavetronix/common-components'
import { CABINET_LINEOPTIONS } from '../components/graphs/FullCabinetLineGraph'
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded'
import dayjs from 'dayjs'

const classes = {
  buttonBase: {
    display: 'block',
    width: '100%',
    height: '100%',
    color: 'black',
    background: 'white',
    borderRadius: '15px',
    padding: '10px'
  },
  imageBase: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '60px',
    marginBottom: 'auto'
  },
  titleText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 20,
    display: 'inline',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: 'black'
  },
  titleIcon: {
    display: 'block',
    maxHeight: '180px'
  }
}

const SCROLL_DIRECTION = {
  RIGHT: 1,
  LEFT: -1
}

export default function SensorCardEx({ statusItem, disableNavigation }) {
  const tableRef = useRef(null)
  const [scrolling, setScrolling] = useState(true)
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DIRECTION.RIGHT)

  useEffect(() => {
    let animationFrameId

    const scrollTable = () => {
      if (scrolling && tableRef.current) {
        tableRef.current.scrollBy({ left: scrollDirection, behavior: 'auto' }) // Use 'smooth' for smoother scrolling

        if (tableRef.current.scrollLeft >= tableRef.current.scrollWidth - tableRef.current.clientWidth) {
          setScrolling(false)
          setScrollDirection(SCROLL_DIRECTION.LEFT)
          setTimeout(() => setScrolling(true), 3000)
        } else if (tableRef.current.scrollLeft <= 0) {
          setScrolling(false)
          setScrollDirection(SCROLL_DIRECTION.RIGHT)
          setTimeout(() => setScrolling(true), 3000)
        }
      }
      animationFrameId = requestAnimationFrame(scrollTable)
    }

    if (scrolling) {
      animationFrameId = requestAnimationFrame(scrollTable)
    }

    return () => cancelAnimationFrame(animationFrameId)
  }, [scrolling, scrollDirection])

  useEffect(() => {
    const handleResize = () => {
      // Optional logic when zooming occurs, like recalculating positions
      setScrolling(false)
      setTimeout(() => setScrolling(true), 500) // Restart scrolling after small delay
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const cabinetColumns = useMemo(() => {
    let branchColumns = []
    if (statusItem.testOverviews.length > 0) {
      branchColumns = [...new Set(statusItem.testOverviews.map(overview => overview.typeName))]
        .map(typeName =>
          CABINET_LINEOPTIONS[typeName]
            ? {
                prettyName: `${CABINET_LINEOPTIONS[typeName].prettyName}`,
                order: CABINET_LINEOPTIONS[typeName].order,
                typeName: typeName
              }
            : { typeName: typeName, order: 1, prettyName: typeName }
        )
        .sort((a, b) => (a.order > b.order ? 1 : -1))
    }

    return branchColumns
  }, [statusItem])

  const overviewMap = useMemo(() => {
    let overviewResult = {
      Dev: {},
      Integration: {},
      Master: {}
    }

    if (statusItem.testOverviews.length > 0) {
      for (let overview of statusItem.testOverviews) {
        overviewResult[overview.branch][overview.typeName] = overview
      }
    }

    return overviewResult
  }, [statusItem])

  const getUrl = () => {
    return `/product?c=${statusItem.cabinetName}`
  }

  const getSuccessChange = dtsReport => {
    if (dtsReport.isSuccessChangeValid && dtsReport.successChange < 0)
      return (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
            <SouthOutlined style={{ fill: WtxColors.INNOVATION_RED }}></SouthOutlined>
            <div>{`${(dtsReport.successChange * 100).toFixed(1)}%`}</div>
          </div>
        </div>
      )
    if (dtsReport.isSuccessChangeValid && dtsReport.successChange > 0)
      return (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
          <NorthOutlined style={{ fill: WtxColors.GROWTH_GREEN }}></NorthOutlined>
          <div>{`${(dtsReport.successChange * 100).toFixed(1)}%`}</div>
        </div>
      )

    return <div>{'--'}</div>
  }

  const getTestTooltip = testInfo => {
    return (
      <Box sx={{ padding: '5px' }}>
        <Box>
          Last run: <span style={{ fontFamily: 'Source Code Pro', fontSize: '12px' }}>{`${formatDate(testInfo.lastRun)}`}</span>
          <span style={{ marginLeft: '5px', fontFamily: 'Source Code Pro', fontSize: '12px' }}>{`${formatTime(
            testInfo.lastRun
          )}`}</span>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '15px' }}>
          <Box sx={{ width: '50%' }}>
            <Box>Success: {testInfo.successCount}</Box>
            <Box>Failure: {testInfo.failureCount}</Box>
          </Box>
          <Box sx={{ width: '50%', float: 'right' }}>
            <Box>{getSuccessChange(testInfo)}</Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const checkLastRun = lastRunTime => {
    const now = dayjs()
    const date = dayjs(lastRunTime)

    if (now.diff(date, 'hour') > 36) {
      return <MoreTimeRoundedIcon fontSize='small' />
    }
  }

  const getBranchData = (branchName, cabinetName) => {
    return (
      <TableRow>
        <TableCell
          key={`${cabinetName}branch${branchName}`}
          sx={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}
        >
          {branchName}
        </TableCell>
        {cabinetColumns.map((testType, index) => {
          if (overviewMap && overviewMap[branchName] && overviewMap[branchName][testType.typeName]) {
            let testTypeInfo = overviewMap[branchName][testType.typeName]

            let branchSuccessPercent = (testTypeInfo.percentSuccess * 100).toFixed(1)
            let branchResult = calculateColorBasedOnPercent(branchSuccessPercent)
            return (
              <TableCell
                key={`${testTypeInfo.cabinetName}cell${index}`}
                align='center'
                sx={{
                  backgroundColor: `${branchResult.backgroundColor}80`,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <Tooltip title={getTestTooltip(testTypeInfo)} sx={{ backgroundColor: 'black' }}>
                  <div>
                    {checkLastRun(testTypeInfo.lastRun)}
                    {branchSuccessPercent}
                  </div>
                </Tooltip>
              </TableCell>
            )
          } else {
            return (
              <TableCell key={`badcell${index}`} align='center'>
                ---
              </TableCell>
            )
          }
        })}
      </TableRow>
    )
  }

  return (
    <div
      style={{ width: '100%' }}
      id={`${statusItem.cabinetName}ViewCard`}
      onClick={_ => {
        if (disableNavigation === false) {
          return (window.location.href = getUrl())
        }
      }}
    >
      <ButtonBase sx={classes.buttonBase}>
        <div>
          <div style={{ textAlign: 'center', margin: '5px 0px 5px 0px' }}>
            <div style={classes.titleText}>{statusItem.cabinetName}</div>
            <div>
              {statusItem.testOverviews && statusItem.testOverviews.length > 0 && statusItem.testOverviews[0].testDevices
                ? statusItem.testOverviews[0].testDevices.map(d => d.product).join(' | ')
                : ''}
            </div>
          </div>
          <div>
            <TableContainer ref={tableRef} onMouseEnter={() => setScrolling(false)} onMouseLeave={() => setScrolling(true)}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>
                      Branch
                    </TableCell>
                    {cabinetColumns.map((column, index) => {
                      let cellId = `${statusItem.cabinetName}${index}Header`
                      return (
                        <TableCell
                          key={cellId}
                          align='center'
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {column.prettyName}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getBranchData('Master', statusItem.cabinetName)}
                  {getBranchData('Integration', statusItem.cabinetName)}
                  {getBranchData('Dev', statusItem.cabinetName)}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </ButtonBase>
    </div>
  )
}
